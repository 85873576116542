<template>
<v-app>
     <drawer-dashboard />
   
    <router-view></router-view>
   

     <!-- <v-content>
      <v-container>
        <v-row>
          <v-col cols="12">
              <v-card
              v-model="admin"
              class="mx-auto"
              max-width="344"
              outlined
            >
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="overline mb-4">
                    OVERLINE
                  </div>
                  <v-list-item-title class="headline mb-1">
                    Headline 5
                  </v-list-item-title>
                  <v-list-item-subtitle>Greyhound divisely hello coldly fonwderfully</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-avatar
                  tile
                  size="80"
                  color="grey"
                ></v-list-item-avatar>
              </v-list-item>

              <v-card-actions>
                <v-btn
                  outlined
                  rounded
                  text
                >
                  Button
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-content> -->
 
</v-app>
</template>
<script>

export default {
  components: {
    DrawerDashboard: () => import ('@/components/DrawerDashboard'),
    },
     data: () => ({
       admin: 'item'
     })
    
}
</script>
<style>
.adminP{
  color:#616161 !important;
}
</style>